<template>
  <div class="goods-detail">
    <div v-if="baseProducts.titles!==undefined" style="width:100%;">
      <div class="product-intro">
        <div class="magnifier-container">
          <div class="magnifier-mian" style="border:1px solid lightgrey;"
               @mousemove="glassMoveHandle"
               @mouseleave="glassLeaveHandle">
            <img ref="orginImg"
                 :src="src"
                 alt="" />
            <div ref="magnifierCover"
                 class="magnifier-cover"></div>
          </div>
          <ul class="magnifier-img-list"
              @mousemove="imgMoveHandle">
            <li v-for="(item,index) of srcs"
                :key="index"
                :src="item"
                :class="{ active: item === src }">
              <img :src="item"
                   alt=""
                   :data-src="item" />
            </li>
          </ul>
          <div ref="magnifierBig"
               class="magnifier-big"></div>
        </div>
        <div class="itemInfo-wrap" style="margin-left:30px;">
          <div class="sku-name">{{ baseProducts.titles?JSON.parse(baseProducts.titles)[langtype]||baseProducts.title:baseProducts.title }}</div>
          <div class="sku-subtitle">{{ baseProducts.subtitles?JSON.parse(baseProducts.subtitles)[langtype]||baseProducts.subtitle:baseProducts.subtitle}}</div>
          <div class="item-detail-price-row">
            <div class="price-box">
              <span>{{$t('goodsDetail.price')}}:</span> <span class="discountPrice prices">{{
                moneyfilter(baseProducts.discountPrice)
              }}</span>
              <span class="price">{{ moneyfilter(baseProducts.price) }}</span>
            </div>
          </div>
          <div class="wrap wrap-sku">
            <div class="product-box">
              <div class="product-content">
                <div class="product-delcom" style="padding:8px;"
                     v-for="(ProductItem, n) in simulatedDATA.specifications"
                     :key="n">
                  <!--                <p>{{ ProductItem.names?JSON.parse(ProductItem.names)[langtype]:''}}</p>-->
                  <ul class="product-footerlist clearfix">
                    <!--                  <p>{{ ProductItem.name}}</p>-->
                    <p>{{ ProductItem.descriptions?JSON.parse(ProductItem.descriptions)[langtype]:ProductItem.description}}</p>
                    <li v-for="(oItem, index) in ProductItem.items"
                        :key="index"
                        @click="specificationBtn(oItem.value, n, $event, index)"
                        :class="[
                      oItem.isShow ? '' : 'noneActive',
                      subIndex[n] == index ? 'productActive' : '',
                    ]">
                      {{ oItem.valuess?(JSON.parse(oItem.valuess)[langtype]||oItem.value):oItem.value}}
                      <!--                    {{ oItem.value}}-->
                    </li>

                  </ul>
                </div>
                <div class="num" style="margin-top:10px;">
                  <span>{{$t('goodsDetail.number')}}</span><el-input-number class="ensad"
                                                                            v-model="num"
                                                                            @change="handleChange"
                                                                            :min="1"
                                                                            :max="10000"
                                                                            label="描述文字"></el-input-number>
                </div>
                <div class="btns">
                  <el-button type="goon"
                             @click="shopping">{{$t('goodsDetail.addgoodscat')}}</el-button>
                  <el-button type="goon"
                             @click="buy">{{$t('goodsDetail.buynow')}}</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="detail-img">
        <div v-html="details"></div>
        <!-- <ul>
          <li v-for="(item,index) in baseProducts.spu_detail_img_list" :key="index"><img :src="item" alt=""></li>
        </ul> -->
      </div>
    </div>
    <div v-else style="width:100%;min-height: 400px;display: flex;flex-direction: row;justify-content: center;">
      <el-empty description="暂无信息" v-loading="true" element-loading-text="拼命加载中" style="z-index:-99;width:100%;"></el-empty>
    </div>
  </div>
</template>

<script>
export default {
  name: "goodsDetail",

  data () {
    return {
      src: "",
      num: 1,
      srcs: [],
      baseProducts: {},
      langtype: 0,
      skuList: [],
      colorShou: 0,
      specShou: 0,
      simulatedDATA: {
        //模拟后台返回的数据 多规格
        specifications: [],
      },
      selectArr: [], //存放被选中的值
      shopItemInfo: {}, //存放要和选中的值进行匹配的数据
      subIndex: [], //是否选中 因为不确定是多规格还是单规格，所以这里定义数组来判断
      price: "", //选中规格的价钱
      selectedSku: [],
      devicesSum: 1,
      choosableSkuList: [],
      details: "",
      aa: "",
      bb: "",
    };
  },

  mounted () {
    setTimeout(() => {
      document.dispatchEvent(new Event("render-event"));
    }, 5000);
  },

  created () {
    switch (localStorage.getItem('lang')) {
      case "zh":
        this.langtype = 0;
        break;
      case "en":
        this.langtype = 1;
        break;
      case "es":
        this.langtype = 6;
        break;
      case "ru":
        this.langtype = 2;
        break;
      case "de":
        this.langtype = 3;
        break;
      case "fr":
        this.langtype = 4;
        break;
      case "ar":
        this.langtype = 5;
        break;
    }
    // console.log(this.langtype)
    var self = this;
    self.productData();
    self.checkItem();
    self.addskuList();
  },

  methods: {
    async addskuList () {
      var self = this;
      let choosableSkuList = await this.api.get(
          this.apiPath.general + "sku/by/spu/" + `${this.$route.query.id}`,
          {}
      );
      console.log(choosableSkuList)
      choosableSkuList.forEach((item) => {
        if (item.specs != null && item.specs[1]) {
          item.difference = item.specs[0].value + "," + item.specs[1].value;
        } else {
          if (item.specs != null)
            item.difference = item.specs[0].value;
        }
      });
      self.skuList = choosableSkuList;
      for (var i in self.skuList) {
        self.shopItemInfo[self.skuList[i].difference] = self.skuList[i]; //修改数据结构格式，改成键值对的方式，以方便和选中之后的值进行匹配
      }
    },
    async specificationBtn (item, n, event, index) {
      // // console.log(item)
      var self = this;
      // // console.log(self.baseProducts.spec_type);
      // // console.log(item)
      if (self.baseProducts.spec_type) {
        self.dynamicDetails(item);
      }
      self.selectColor(item);
      if (self.selectArr[n] != item) {
        self.selectArr[n] = item;
        self.subIndex[n] = index;
      } else {
        self.selectArr[n] = "";
        self.subIndex[n] = -1; //去掉选中的颜色
      }
      self.checkItem();
    },
    //选中颜色图片
    async dynamicDetails (item) {
      var self = this;
      // 动态详情
      // console.log(self.skuList[0])
      if (self.skuList[1]) {
        self.skuList[1].items.forEach((i) => {
          if (item == i.value) {
            let data = {
              spu_id: this.baseProducts.id,
              spec_key_id: i.id,
            };
            self.getDetailEditor(data);
          }
        });
      } else if (self.skuList[0]) {
        self.skuList[0].items.forEach((i) => {
          if (item == i.value) {
            let data = {
              spu_id: this.baseProducts.id,
              spec_key_id: i.id,
            };
            self.getDetailEditor(data);
          }
        });
      }
    },
    async getDetailEditor (data) {
      console.log(data)
      if (data.spec_key_id) {
        // data.spec_key_id = 323
      }
      let res = await this.api.post(this.apiPath.detailEditor, data);
      console.log(res)
      if (res.data == '') {
        return
      }
      if (res.detailses) {
        if (JSON.parse(res.detailses)[this.langtype]) {
          this.details = JSON.parse(res.detailses)[this.langtype]
        } else {
          this.details = res.details;

        }
      } else {
        this.details = res.details;
      }

    },
    selectColor (color) {
      console.log(this.choosableSkuList)
      this.choosableSkuList.forEach((item) => {
        if (item.specs != null && item.specs[0].value == color) {
          this.aa = item.specs[0].value;
        }
        if (item.specs != null && item.specs[1]) {
          if (item.specs[1].value == color) {
            this.bb = item.specs[1].value;
          }
          if (
              this.aa == item.specs[0].value &&
              this.bb == item.specs[1].value
          ) {
            this.src = item.imgs ? (JSON.parse(item.imgs)[this.langtype] ? JSON.parse(item.imgs)[this.langtype] : item.img) : item.img;
            this.srcs[0] = item.img;
            this.$nextTick(() => {
              this.$refs.magnifierBig.style.backgroundImage = `url(${item.img})`; //设置大图
            });
          }
        } else {
          if (item.specs != null && this.aa == item.specs[0].value) {
            this.src = item.imgs ? (JSON.parse(item.imgs)[this.langtype] ? JSON.parse(item.imgs)[this.langtype] : item.img) : item.img;
            this.srcs[0] = item.img;
            this.$nextTick(() => {
              this.$refs.magnifierBig.style.backgroundImage = `url(${item.img})`; //设置大图
            });
          }
        }
      });
    },
    async checkItem () {
      var self = this;
      let skuList = await this.api.get(
          this.apiPath.general +
          "spu/" +
          `${this.$route.query.id}` +
          "/specValue",
          {}
      );
      // console.log(skuList)
      var obj1 = {}
      var option = []
      for (var item of skuList) {
        if (obj1[item.id]) {

        } else {
          obj1[item.id] = item
          option.push(item)
        }
      }
      // console.log(obj1)
      var result = []; //定义数组储存被选中的值
      // console.log("-----------");
      console.log(option)

      this.simulatedDATA.specifications = option;
      // console.log(this.selectArr)
      for (var i in option) {
        result[i] = self.selectArr[i] ? self.selectArr[i] : "";
      }
      // console.log(result)
      for (var i in option) {
        this.selectedSku.push(result[i]);
        var last = result[i]; //把选中的值存放到字符串last去
        for (var k in option[i].items) {
          result[i] = option[i].items[k].value; //赋值，存在直接覆盖，不存在往里面添加name值
          console.log(result)
          option[i].items[k].isShow = self.isMay(result); //在数据里面添加字段isShow来判断是否可以选择
        }
        result[i] = last; //还原，目的是记录点下去那个值，避免下一次执行循环时被覆盖
      }
      // console.log(result)
      // console.log(this.shopItemInfo)
      if (this.shopItemInfo[result]) {
        // console.log(this.shopItemInfo)

        // this.baseProducts.discountPrice =
        //   this.shopItemInfo[result].price || "";

        if (this.shopItemInfo[result].discountPrice) {
          // console.log('有折扣')
          this.baseProducts.discountPrice = this.shopItemInfo[result].discountPrice
          this.baseProducts.price = this.shopItemInfo[result].price
        } else if (this.shopItemInfo[result].price) {
          // console.log('无折扣')
          this.baseProducts.discountPrice = this.shopItemInfo[result].price
          this.baseProducts.price = ''
        }
      }
      self.$forceUpdate(); //重绘
    },
    isMay (result) {
      // console.log(result)
      // console.log(this.shopItemInfo[result])
      for (var i in result) {
        if (result[i] == "") {
          return true; //如果数组里有为空的值，那直接返回true
        }
      }
      if (!this.shopItemInfo[result]) {
        //判断是否有这条可选路线
        return false;
      }
      return this.shopItemInfo[result].stock == 0 ? false : true; //匹配选中的数据的库存，若不为空返回true反之返回false
    },
    async productData () {
      let baseProducts = await this.api.get(
          this.apiPath.general + "spu/" + `${this.$route.query.id}/` + "detail",
          {}
      );
      console.log("产品信息",baseProducts)
      // this.details = baseProducts.details;
      if (localStorage.getItem('lang') == 'zh' || localStorage.getItem('lang') == 'cn') {
        this.details = baseProducts.details
      } else {
        this.details = baseProducts.detailses ? (JSON.parse(baseProducts.detailses)[this.langtype] || baseProducts.details) : baseProducts.details;
      }
      this.colorShou = baseProducts.defaultSkuId; //获取默认sku
      this.specShou = baseProducts.defaultSkuId;
      let skuList = await this.api.get(
          this.apiPath.general +
          "spu/" +
          `${this.$route.query.id}` +
          "/specValue",
          {}
      );
      let choosableSkuList = await this.api.get(
          this.apiPath.general + "sku/by/spu/" + `${this.$route.query.id}`,
          {}
      );
      this.choosableSkuList = choosableSkuList;
      this.skuList = skuList;
      this.srcs = baseProducts.spuImgList;
      // this.srcs=baseProducts.spuImgLists?baseProducts.spuImgLists[this.langtype]:'';

      this.baseProducts = baseProducts;
      // console.log(this.baseProducts)
      this.src = this.srcs[0]; //  默认选中第一张图片
      this.$nextTick(() => {
        // "---------------------------------------------------------------------------------------------------------------------------------大图详情"
        this.$refs.magnifierBig.style.backgroundImage = `url(${baseProducts.spuImgList[0]})`; //设置大图
        // this.$refs.magnifierBig.style.backgroundImage = `url(${baseProducts.spuImgLists?baseProducts.spuImgLists[this.langtype][0]:''})`;
      });
      this.disposeSku(choosableSkuList);
    },

    disposeSku (list) {
      // console.log(list)
      list.forEach((item, index) => {
        // console.log(item, index)
        if (item.specs != null && item.specs[1]) {
          item.codes = item.specs[0].value + "," + item.specs[1].value;
        } else {
          if (item.specs != null) {
            // console.log(item.specs[0])
            item.codes = item.specs[0].value;
          }
        }
      });
    },

    addSpec (item) {
      this.specShou = item.id;
      this.baseProducts.discountPrice = item.price;
    },
    handleChange (value) {
      // console.log(value);
    },
    // buy () {
    //   // 立即购买
    //   // console.log('立即购买');
    //   localStorage.setItem("buyType", 2);
    //   // let userInfo = JSON.parse(localStorage.getItem("userInfo"))
    //   const token = localStorage.getItem('token')
    //   if (token) {
    //     // console.log(!this.selectedSku.slice(-2)[0]);
    //     if (!this.selectedSku.slice(-2)[0] || !this.selectedSku.slice(-1)[0]) {
    //       this.$message({
    //         message: '请选择商品规格',
    //         type: 'warning'
    //       });
    //       return
    //     }
    //     let form = {
    //       product_id: '',
    //       product_num: this.num
    //     }
    //     this.choosableSkuList.forEach(item => {
    //       if (item.specs[1]) {
    //         if (this.selectedSku.slice(-1)[0] == item.specs[1].value && this.selectedSku.slice(-2)[0] == item.specs[0].value) {
    //           form.product_id = item.code
    //         }
    //       } else {
    //         if (this.selectedSku[1] == item.specs[0].value) {
    //           // console.log(form.product_id);
    //           form.product_id = item.code
    //         }
    //       }
    //     })
    //     this.getSkuOrderInfo(form)
    //   } else {
    //     this.$message({
    //       message: '请先登录',
    //       type: 'warning'
    //     });
    //     setTimeout(() => {
    //       this.$router.push("/login");
    //     }, 500)
    //   }
    // },
    buy () {
      // 立即购买
      // // console.log("立即购买");
      localStorage.setItem("buyType", 2);
      // let userInfo = JSON.parse(localStorage.getItem("userInfo"))
      // // console.log(!this.selectedSku.slice(-2)[0]);
      if (!this.selectedSku.slice(-2)[0] || !this.selectedSku.slice(-1)[0]) {
        this.$message({
          message: "请选择商品规格",
          type: "warning",
        });
        return;
      }
      let form = {
        product_id: "",
        product_num: this.num,
      };
      this.choosableSkuList.forEach((item) => {
        if (item.specs[1]) {
          if (
              this.selectedSku.slice(-1)[0] == item.specs[1].value &&
              this.selectedSku.slice(-2)[0] == item.specs[0].value
          ) {
            form.product_id = item.code;
          }
        } else {
          if (this.selectedSku[1] == item.specs[0].value) {
            // // console.log(form.product_id);
            form.product_id = item.code;
          }
        }
      });
      this.getSkuOrderInfo(form);
    },
    async getSkuOrderInfo (form) {
      console.log(form)
      let addShopping = await this.api.post(this.apiPath.getSkuOrderInfo, form);
      console.log(addShopping)
      const token = localStorage.getItem("token");
      // // console.log(addShopping);
      localStorage.setItem("commodityInfo", JSON.stringify([addShopping]));
      if (token) {
        this.$router.push({
          name: "pay",
        });
      } else {
        this.$router.push({
          name: "noLogin",
        });
      }
    },
    shopping () {
      // 加入购物车
      // let userInfo = JSON.parse(localStorage.getItem("userInfo"))
      const token = localStorage.getItem("token");
      if (token) {
        if (!this.selectedSku.slice(-1)[0] || !this.selectedSku.slice(-2)[0]) {
          this.$message({
            message: "请选择完整的商品规格",
            type: "warning",
          });
          return;
        }
        let form = {
          code: "",
          num: this.num,
        };
        this.choosableSkuList.forEach((item) => {
          if (item.specs[1]) {
            if (
                this.selectedSku.slice(-1)[0] == item.specs[1].value &&
                this.selectedSku.slice(-2)[0] == item.specs[0].value
            ) {
              form.code = item.code;
            }
          } else {
            if (this.selectedSku[1] == item.specs[0].value) {
              form.code = item.code;
            }
          }
        });
        this.setShopping(form);
      } else {
        this.$message({
          message: "请先登录",
          type: "warning",
        });
        setTimeout(() => {
          this.$router.push("/login");
        }, 500);
      }
    },
    async setShopping (form) {
      let addShopping = await this.api.post(this.apiPath.addShopping, form);
      if (addShopping.code == 1) {
        let num = Math.round(Math.random() * 1100);
        localStorage.setItem("shoppingNum", num);
        this.$message({
          message: "添加购物车成功",
          type: "success",
        });
      } else {
        this.$message.error(res.message);
      }
    },
    /* 鼠标移入事件 */
    imgMoveHandle: function (event) {
      let { src } = event.target.dataset;
      if (src) {
        let srcObj = this.srcs.find((c) => c === src);
        if (srcObj) {
          this.src = srcObj;
          this.$refs.magnifierBig.style.backgroundImage = `url(${srcObj})`;
        }
      }
    },
    /** 放大镜移入 **/
    glassMoveHandle: function (event) {
      // 鼠标的位置
      let x = event.clientX,
          y = event.clientY;
      // 获取元素
      let imgEl = this.$refs.orginImg.getBoundingClientRect();
      // 获取元素左上角的坐标
      let cx = imgEl.left,
          cy = imgEl.top;
      let coverEl = this.$refs.magnifierCover.getBoundingClientRect();
      let tx = x - cx - coverEl.width / 2;
      let ty = y - cy - coverEl.height / 2;
      let maxX = imgEl.width - coverEl.width;
      if (tx < 0) tx = 0;
      else {
        tx = tx > maxX ? maxX : tx;
      }
      let maxY = imgEl.height - coverEl.height;
      if (ty < 0) ty = 0;
      else {
        ty = ty > maxY ? maxY : ty;
      }
      this.$refs.magnifierCover.style.display = "block";
      this.$refs.magnifierBig.style.display = "block";
      this.$refs.magnifierBig.style.backgroundPosition = `${(tx / maxX) * 100
      }% ${(ty / maxY) * 100}%`;
      this.$refs.magnifierCover.style.left = `${tx}px`;
      this.$refs.magnifierCover.style.top = `${ty}px`;
    },
    /***移出事件*/
    glassLeaveHandle: function () {
      this.$refs.magnifierCover.style.display = "none";
      this.$refs.magnifierBig.style.display = "none";
    },
  },
};
</script>

<style lang="less" scoped>
.goods-detail {
  width: 1210px;
  margin: auto;
  .product-intro {
    display: flex;
    .itemInfo-wrap {
      width: 100%;
      margin: 10px;
      .sku-name {
        font: 700 24px Arial, "microsoft yahei";
        color: #666;
        padding-top: 10px;
        line-height: 28px;
        margin-bottom: 5px;
      }
      .item-detail-price-row {
        width: 100%;
        // height: 200px;
        background: #eee;
        padding: 5px;
        margin-top: 20px;
        .price-box {
          color: #999;
          font-size: 14px;
          margin-right: 15px;
          .discountPrice {
            font-size: 22px;
            margin-right: 5px;
          }
          .prices {
            color: red;
            margin-left: 5px;
          }
          .price {
            text-decoration: line-through;
          }
        }
      }
    }
  }
  .detail-img {
    ul {
      border: 1px dotted #eee;
    }
    ul li {
      width: 100%;
      text-align: center;
    }
  }
}
.magnifier-container {
  width: 450px;
  border: 1px solid #eee;
  position: relative;
  .magnifier-mian {
    position: relative;
    width: 450px;
    height: 450px;
    margin-bottom: 15px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
    .magnifier-cover {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 220px;
      height: 220px;
      background: #7f0114;
      opacity: 0.4;
      cursor: Move;
    }
  }
  .magnifier-big {
    position: absolute;
    display: none;
    top: 0;
    left: 480px;
    width: 450px;
    height: 450px;
    border: 1px solid #eee;
    background-size: 198%;
    z-index: 99;
  }
  ul {
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
    list-style: none;
    display: flex;
    justify-content: left;
    li {
      margin-left: 10px;
      img {
        display: block;
        width: 50px;
        height: 50px;
      }
    }
    li.active {
      border: 2px solid #eee;
    }
  }
}
.forum {
  margin-top: 135px;
}
.wrap-sku {
  .product-box {
    width: 1200px;
    display: block;
    margin: 0 auto;
  }
  .product-content {
    .btns {
      margin-top: 20px;
    }
  }
  .product-delcom {
    color: #323232;
    font-size: 22px;
    border-bottom: 1px solid #eeeeee;
    padding: 30px 0;
    p {
      font-size: 16px;
    }
  }
  .product-footerlist {
    margin-top: 10px;
  }
  .product-footerlist li {
    border: 1px solid #606060;
    border-radius: 5px;
    color: #606060;
    text-align: center;
    padding: 10px 30px;
    float: left;
    margin-right: 20px;
    cursor: pointer;
    font-size: 16px;
  }
  .product-footerlist li.productActive {
    background-color: #7f0114;
    color: #fff;
    font-size: 16px;
    border: 1px solid #7f0114;
  }
  .product-footerlist li.noneActive {
    background-color: #ccc;
    opacity: 0.4;
    color: #000;
    pointer-events: none;
  }
  .price {
    font-size: 30px;
    height: 60px;
    line-height: 60px;
  }
}
.num {
  font-size: 16px;
}
.el-button--goon {
  color: #fff;
  background-color: #7f0114;
  border-color: #7f0114;
  height: 44px;
  line-height: 34px;
  padding: 0 20px;
  margin-right: 100px;
}
.el-button--goon:focus,
.el-button--goon:hover {
  color: #fff;
  background-color: #813f49;
  border-color: #813f49;
  height: 44px;
  line-height: 34px;
  padding: 0 20px;
}
/deep/ .el-input-number:hover {
  border-color: #813f49 !important;
}
@media screen and (max-width: 750px) {
  .goods-detail {
    padding: 0 15px;
    width: 100%;
  }
  .itemInfo-wrap {
    margin: 0 !important;
  }
  .product-delcom {
    padding: 0 !important;
  }
  .item-detail-price-row {
    padding: 5px 10px !important;
    margin: 6px 0 !important;
    box-sizing: border-box !important;
  }
  .price-box {
    margin-right: 0 !important;
  }
  .product-delcom {
    margin: 10px 0;
  }
  .sku-name {
    font-size: 18px !important;
  }
  .product-footerlist {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    // display: block !important;
    // padding: 5px 0;
  }
  .wrap-sku .product-footerlist li {
    margin: 0 5px !important;
    padding: 3px 7px !important;
    background: #e9e9e9;
    color: #666;
    border: none;
  }
  .product-delcom:not(:first-child) {
    margin-top: 20px;
  }
  /deep/.el-input-number .el-input__inner {
    line-height: 30px !important;
    height: 30px !important;
  }
  .ensad {
    height: 30px !important;
  }
  /deep/.el-input-number {
    width: 114px !important;
    line-height: 28px !important;
  }
  /deep/.el-input-number__decrease,
  /deep/.el-input-number__increase {
    width: 30px !important;
  }
  .wrap-sku .product-delcom {
    border-bottom: 0 !important;
  }
  .wrap-sku .product-delcom p {
    // display: block !important;
    // width: 100%;
    margin-bottom: 8px;
    font-size: 14px;
    padding-left: 5px;
    box-sizing: border-box;
    font-weight: bold;
    color: #4d4d4d;
  }
  .sku-subtitle {
    display: none !important;
  }
  .num {
    margin-top: 20px;
    padding-left: 5px;
    box-sizing: border-box;
  }
  .num span {
    font-weight: bold;
    color: #4d4d4d;
    font-size: 14px;
    margin-right: 12px;
    // display: block;
  }
  .el-button--goon {
    height: 36px !important;
    margin: 0 !important;
  }
  .wrap-sku .product-content .btns {
    margin: 20px 0;
    display: flex;
    justify-content: space-around;
  }
  .product-intro {
    display: block !important;
    .magnifier-container {
      width: 100%;
    }
    .magnifier-mian {
      width: 100%;
      height: 350px;
    }
  }
  .wrap-sku .product-box {
    width: 100%;
  }
  .wrap-sku .product-footerlist li {
    padding: 10px;
    margin-top: 5px;
    font-size: 14px;
  }
  .el-button--goon {
    margin: 20px;
  }
  #video {
    width: 200px;
  }
  .product-footerlist {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    align-items: center;
    li {
      margin-right: 15px !important;
    }
  }
}
</style>
<style>
@media screen and (max-width: 750px) {
  #video {
    width: 100% !important;
    height: 250px !important;
  }
}
</style>
